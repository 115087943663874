:root {
  --tui-heading-font: 'Montserrat', -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif;
  --tui-text-font: 'IBM Plex Sans', -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif;

  --tui-font-heading: var(--tui-heading-font);
  --tui-font-text: var(--tui-text-font);

  --tui-font-heading-1: bold 50px/56px var(--tui-font-heading);
  --tui-font-heading-2: bold 40px/44px var(--tui-font-heading);
  --tui-font-heading-3: bold 30px/36px var(--tui-font-heading);
  --tui-font-heading-4: bold 28px/32px var(--tui-font-heading);
  --tui-font-heading-5: bold 24px/28px var(--tui-font-heading);
  --tui-font-heading-6: bold 20px/24px var(--tui-font-text);

  --tui-font-text-xl: normal 19px/28px var(--tui-font-text);
  --tui-font-text-l: normal 17px/24px var(--tui-font-text);
  --tui-font-text-m: normal 15px/24px var(--tui-font-text);
  --tui-font-text-s: normal 13px/20px var(--tui-font-text);
  --tui-font-text-xs: normal 11px/16px var(--tui-font-text);

  --tui-base-01: #FFFFFF;
  --tui-base-02: #FAFAFA;
  --tui-base-03: #F5F5FB;

  --tui-primary: #4F4EB4;
  --tui-primary-hover: #6564C3;
  --tui-primary-active: #35348B;

  --tui-secondary: #EBEBF8;
  --tui-secondary-hover: #EBEBFF;
  --tui-secondary-active: #B8B8E3;
  
  --tui-link: #526ED3;
  --tui-link-hover: #6C86E2;

  letter-spacing: 0.03em;

  button {
    letter-spacing: inherit;
  }
}

body {
  background-color: var(--tui-primary);
}

